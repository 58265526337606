export const locale = {
  lang: 'az',
  data: {
    MENU: {
      USER:{
        ACCOUNT:'Hesabım'
      },
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce'
      },
      APPS: {
        SECTION: 'Səhifələr',
        EMAIL: 'Email',
        ATTENDANCE: 'Davamiyyət',
        ORDERS: 'Əmrlər',
        ACCOUNT: 'Hesabım',
        VACATIONS: 'Məzuniyyət',
        SALARY: 'Əmək haqqı',
        TRANINGS: 'Təlimlər',
        REQUESTS: 'Sorğular',
        TODO: 'Todo',
        CALENDAR: 'Vaxt Cədvəli',
        HOME: 'Əsas Səhifə',
      
      },
      ADMIN: {
        COLLAPSIBLE: 'İşçilər',
        LIST: 'Siyahı',
        VIEW: 'Siyahı',
        USER: {
          LIST: 'Siyahı',
          VIEW: 'Siyahı',
          EDIT: 'Düzəliş',
        },
      },
      PAGES: {
        SECTION: 'Pages',
        AUTH: {
          COLLAPSIBLE: 'Authentication',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Forgot Password V1',
          FORGOTPASSWORD2: 'Forgot Password V2',
          RESETPASSWORD1: 'Reset Password V1',
          RESETPASSWORD2: 'Reset Password V2'
        },
        ACCOUNTSETTINGS: 'Account Settings',
        PROFILE: 'Profile',
        FAQ: 'FAQ',
        KB: 'Knowledge Base',
        PRICING: 'Pricing',
        BLOG: {
          COLLAPSIBLE: 'Blog',
          LIST: 'List',
          DETAILS: 'Details',
          EDIT: 'Edit'
        },
        MAIL: {
          COLLAPSIBLE: 'Mail Template',
          WELCOME: 'Welcome',
          RESET: 'Reset Password',
          VERIFY: 'Verify',
          DEACTIVATE: 'Deactivate',
          INVOICE: 'Invoice',
          PROMOTIONAL: 'Promotional'
        },
        MISC: {
          COLLAPSIBLE: 'Miscellaneous',
          COMINGSOON: 'Coming Soon',
          NOTAUTH: 'Not Authorized',
          MAINTENANCE: 'Maintenance',
          ERROR: 'Error'
        }
      },
      UI: {
        SECTION: 'User Interface',
        TYPOGRAPHY: 'Typography',
        COLORS: 'Colors',
        FEATHER: 'Feather',
        CARDS: {
          COLLAPSIBLE: 'Cards',
          BADGE: 'New',
          BASIC: 'Basic',
          ADVANCE: 'Advance',
          STATISTICS: 'Statistics',
          ANALYTICS: 'Analytics',
          ACTIONS: 'Actions'
        },
        COMPONENTS: {
          COLLAPSIBLE: 'Components',
          ALERTS: 'Alerts',
          AVATAR: 'Avatar',
          BADGES: 'Badges',
          BREADCRUMBS: 'Breadcrumbs',
          BUTTONS: 'Buttons',
          CAROUSEL: 'Carousel',
          COLLAPSE: 'Collapse',
          DIVIDER: 'Divider',
          DROPDOWNS: 'Dropdowns',
          GROUP: 'List Group',
          OBJECTS: 'Media Objects',
          MODALS: 'Modals',
          COMPONENT: 'Navs',
          PAGINATION: 'Pagination',
          PBADGES: 'Pill Badges',
          PILLS: 'Pills',
          POPOVERS: 'Popovers',
          PROGRESS: 'Progress',
          RATINGS: 'Ratings',
          SPINNER: 'Spinner',
          TABS: 'Tabs',
          TIMELINE: 'Timeline',
          TOASTS: 'Toasts',
          TOOLTIPS: 'Tooltips'
        },
        EX: {
          COLLAPSIBLE: 'Extension',
          SWEET_ALERTS: 'Sweet Alerts',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'Slider',
          DRAGDROP: 'Drag & Drop',
          TOUR: 'Tour',
          CLIPBOARD: 'Clipboard',
          MEDIAPLAYER: 'Media Player',
          CONTEXTMENU: 'Context Menu',
          SWIPER: 'Swiper',
          TREEVIEW: 'Tree View',
          I18N: 'I18n'
        },
        LAYOUTS: {
          COLLAPSIBLE: 'Page Layouts',
          COLLAPSED_MENU: 'Collapsed Menu',
          BOXED_LAYOUT: 'Boxed Layout',
          WITHOUT_MENU: 'Without Menu',
          LAYOUT_EMPTY: 'Layout Empty',
          LAYOUT_BLANK: 'Layout Blank'
        }
      },
      FT: {
        SECTION: 'Forms & Tables',
        ELEMENT: {
          COLLAPSIBLE: 'Form Elements',
          INPUT: 'Input',
          INPUTGROUPS: 'Input Groups',
          INPUTMASK: 'Input Mask',
          TEXTAREA: 'Textarea',
          CHECKBOX: 'Checkbox',
          RADIO: 'Radio',
          SWITCH: 'Switch',
          SELECT: 'Select',
          NUMBERINPUT: 'Number Input',
          FILEUPLOADER: 'File Uploader',
          QUILLEDITOR: 'Quill Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'Date & Time Picker'
        },
        LAYOUTS: 'Form Layouts',
        WIZARD: 'Form Wizard',
        VALIDATION: 'Form Validations',
        REPEATER: 'Form Repeater',
        TABLE: 'Table',
        DATATABLES: 'DataTables'
      },
      CM: {
        SECTION: 'Charts & Maps',
        CHARTS: {
          COLLAPSIBLE: 'Charts',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS'
        },
        MAPS: 'Google Maps'
      },
      OTHERS: {
        SECTION: 'Others',
        LEVELS: {
          COLLAPSIBLE: 'Menu Levels',
          SECOND: 'Second Level',
          SECOND1: {
            COLLAPSIBLE: 'Second Level',
            THIRD: 'Third Level',
            THIRD1: 'Third Level'
          }
        },
        DISABLED: 'Disabled Menu',
        DOCUMENTATION: 'Documentation',
        SUPPORT: 'Raise Support'
      }
    },
    earning:'Qazanc',
    deduction:'Tutulma',
    deduction2:'Tutulma',
    salary:'Əmək haqqı',
    vacation:'Məzuniyyət',
    spf:'DSMF',
    unemployment:'işsizlik',
    "medical.insurance":'Tibbi siğorta ',
    total:'Cəmi',
    month:'Ay',
    tobepaid:'Ödənilən',
    employee:'Əməkdaş',
    company:'Şirkət',
    date:'Tarix',
    main:'Əsas',
    name:'Ad',
    surname:'Soyad',
    firstname:'Ad',
    fullname:'Ad Soyad',
    trainings: 'Təlimlər',
    condition:'Şərti',
    earned:'Qazanılan',
    common :'Ümumi',
    used:'Istifadə edilən',
    balanced:'qalan',
    experience:'Təcrübəyə görə',
    child:'Uşağa görə',
    'salary.table':'üçün əmək haqqı cədvəli',
    pin:'Fin',
    email:'E-mail',
    position:'Vəzifə',
    department:'Şöbə',
    status:'Status',
    address:'Adress',
    photo:'Şəkil',
    seria:'Seriya',
    issiedBy:'Ş.V verən orqanın adı',
    family:'Ailə üzvləri',
    relationship:'Qohumluq',
    snf:'S.A.A',
    dob:'Doğum tarixi',
    age:'Yaş',
    worked:'iş günləri',
    dayOff:'qeyri-iş günləri',
    type:'Növü',
    detail:'Ətraflı',
    project:'Layihe',
    description:'Qeyd',
    vacationDays:'Məzuniyyət günü',
    vacationDate:'Məzuniyyətə getme tarixi',
    orderDate:'Əmrin verilmə tarixi',
    balancedVacationDays:'Qalan məzuniyyət günü',
    close:'Bağla',
    day:'Gün',
    edit:'Düzəliş et',
    add:' Əlavə et',
    dateTo:'Tarixə',
    dateFrom:'Tarixdən',
    newRequest:'Yeni sorğunun yaradılması',
    request:'Sorğu',
    amount:'Məbləğ',
    answer:'Cavab',
    answerDate:'Cavablama tarixi',
    types:'Növləri',
    statuses:'Statusları',
    selectType:'Tipi seç',
    logout:'Çıxış',
    save:'Yadda saxla',
    cancel:'Imtina',
    changePass:'Şifrəni dəyiş',
    cpassword:'Şifrəni təsdiqlə',
    password:'Şifrə',
    orderTypes:'Əmr növləri',
    newPassword:'Yeni Şifrə',
    approved:'Təsdiqləndi',
    pending:'Gözləmədə',
    rejected:'İmtina edildi',
    send:'Göndər',
    back:'Geriyə',
    approve:'Təsdiq',
    setPass:'Şifrə təyin edin',
    enteringSystem:'Sistemə daxil olunması üçün şifrənin təyini',
    passrequerid:'Şifrə vacibdir.',
    regular: 'İş saatları',
    weekend: 'Həftə sonu',
    overtime: 'Əlavə iş saatı',
    "business trip": 'Ezamiyyət',
    "public holiday": 'Bayram günü',
    "vacation/annual": "İllik məzuniyyet",
    "academic": "Akademik məzuniyyət",
    sick: "Xəstəlik məzuniyyət",
    unpaid: "Ödənişsiz məzuniyyət",
    maternity: 'Analıq məzuniyyəti',
    social: 'Sosial',
    compensation: "Kompensasiya",
    dayoff: 'istirahət günü',
    viewall:'Hamısı',
    'sick.leave':'Xəstəlik',
    yearly:"İllik",
    year:"İl",
    monthly: "Aylıq",
    jobs:"Vakansiyalar",
    more:"Ətraflı",
    seemore:"Daha çox",
    "evening.payment":"Əlavə iş",
  }
};
