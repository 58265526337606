import { CoreMenu } from '@core/types';


export const menu: CoreMenu[] = [
  // Apps & Pages
  {
    id: 'apps',
    type: 'section',
    title: 'Səhifələr',
    translate: 'section',
    icon: 'package',
    children: [
      {
        id: 'home',
        title: 'Əsas səhifə',
        type: 'item',
        icon: 'home',
        translate: 'Əsas səhifə',
        url: 'dashboard/statistic'
      },
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        icon: 'users',
        translate: 'Idmançılar',
        url: 'users'
      },
      // {
      //   id: 'adult',
      //   title: 'Adult',
      //   type: 'item',
      //   icon: 'users',
      //   translate: 'Böyüklər',
      //   url: 'users/adult'
      // },
      // {
      //   id: 'teenager',
      //   title: 'Teenager',
      //   type: 'item',
      //   icon: 'users',
      //   translate: 'Gənclər',
      //   url: 'users/teenager'
      // },
      // {
      //   id: 'young',
      //   title: 'Young',
      //   type: 'item',
      //   icon: 'users',
      //   translate: 'Yeniyetmələr',
      //   url: 'users/young'
      // },

      {
        id: 'fight',
        title: 'Fights',
        type: 'item',
        icon: 'box',
        translate: 'Döyüşlər',
        url: 'fight/fight-list'
      },
      {
        id: 'call',
        title: 'invite',
        type: 'item',
        icon: 'box',
        role: "Admin",
        translate: 'Çağırışlar',
        url: 'call/call-list'
      },
      {
        id: 'invited',
        title: 'Invited',
        translate: 'Çağırışlar',
        type: 'item',
        url: 'call/invited',
        role: "User",
        icon: 'circle',

      },
    
    ]
  }
];
