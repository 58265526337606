<!-- Footer -->
<!-- <div class=" text-right">
  <span class="mt-25">
    COPYRIGHT &copy; {{ year }}
    <a href="https://knockoutboxingclub.az" target="_blank">Knockout - Dashboard</a>
    <span >, All rights Reserved</span>
  </span>
</div> -->
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>