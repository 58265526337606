<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 *role="role" class="brand-text mb-0">Admin Panel</h2>
        <h2 *ngIf="!currentUser.firstname" class="brand-text mb-0">KBC</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>
<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none  mr-md-3" *ngIf="!coreConfig?.layout?.menu?.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang]?.flag }}"></i><span
          class="selected-language">{{ languageOptions[_translateService.currentLang]?.title }}</span></a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang]?.flag }}"></i> {{ languageOptions[lang]?.title }}
        </a>

      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'fa fa-sun-o' : 'fa fa-moon-o'"
          class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <li *ngIf="currentUser.firstname" ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container>
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{currentUser.username}}</span>
            <span class="user-status">{{currentUser?.fullname}}</span>
          </div>
          <span *ngIf="currentUser && !currentUser.photo" class="avatar">
            <img class="round" src="assets/images/logo/logo.png" style="object-fit: contain;" alt="avatar" height="40"
              width="40" /><span class="avatar-status-online"></span></span>

          <span *ngIf="currentUser && currentUser.photo" class="avatar">
            <img class="round" src="{{currentUser.photo}}" style="object-fit: contain;" alt="avatar" height="40"
              width="40" /><span class="avatar-status-online"></span></span>

        </ng-container> 
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">

        <div class="dropdown-divider"></div>

        <a *ngIf="role=='User'" ngbDropdownItem [routerLink]="['/users/my']">
          <span [data-feather]="'lock'" [class]="'mr-50'"></span>Hesabım</a>
        <a ngbDropdownItem [routerLink]="['/users/change-pass']">
          <span [data-feather]="'lock'" [class]="'mr-50'"></span> {{'changePass' | translate}}</a>

        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> {{'logout' |
          translate}}</a>
        <!-- <ng-container *ngFor="let lang of _translateService.getLangs()">
          <ng-container *ngIf="languageOptions[lang]?.title != languageOptions[_translateService.currentLang]?.title ">
            <a ngbDropdownItem (click)="setLanguage(lang)" class="d-md-none d-block">
              <i class="flag-icon flag-icon-{{ languageOptions[lang]?.flag }}"></i> {{ languageOptions[lang]?.title }}

            </a>
          </ng-container>
        </ng-container> -->

        <!-- <div class="text-center d-md-none d-block">
          <a (click)="toggleDarkSkin()">
            <span [ngClass]="currentSkin === 'dark' ? 'fa fa-sun-o' : 'fa fa-moon-o'"
              class="ficon font-medium-5 feather"></span>
          </a>
        </div> -->
      </div>
    </li>
    <li>
      <a *ngIf="!currentUser.firstname" [routerLink]="['/pages/authentication/register']" class="btn btn-info">Qeydiyyat</a>
      <a *ngIf="!currentUser.firstname" [routerLink]="['/pages/authentication/login']" class="btn btn-success ml-1">Daxil ol</a>
    </li>
  </ul>
</div>