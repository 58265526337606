<!-- *ngIf="rate>.5" -->

<fieldset class="rating" >
    <!-- 5 -->
    <input  [checked]="rate == 5 ? true : false" type="checkbox" id="star5" name="rating" value="5" />
    <label class="full" for="star5" title="Awesome - 5 stars"></label>
   <!-- 4.5 -->
    <input  [checked]="rate == 4.5 ? true : false" type="checkbox" id="star4half" name="rating" value="4 and a half" />
    <label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>
   <!-- 4 -->
    <input   [checked]="rate == 4 ? true : false" type="checkbox" id="star4" name="rating" value="4" />
    <label class="full" for="star4" title="Pretty good - 4 stars"></label>
   <!-- 3.5 -->
    <input   [checked]="rate == 3.5 ? true : false"  type="checkbox" id="star3half" name="rating" value="3 and a half" />
    <label class="half" for="star3half" title="Meh - 3.5 stars"></label>
   <!-- 3 -->
    <input  [checked]="rate == 3 ? true : false"  type="checkbox" id="star3" name="rating" value="3" />
    <label class="full" for="star3" title="Meh - 3 stars"></label>
   <!-- 2.5 -->
    <input  [checked]="rate == 2.5 ? true : false"  type="checkbox" id="star2half" name="rating" value="2 and a half" />
    <label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>
   <!-- 2 -->
    <input   [checked]="rate == 2 ? true : false"  type="checkbox" id="star2" name="rating" value="2" />
    <label class="full" for="star2" title="Kinda bad - 2 stars"></label>
   <!-- 1.5 -->
    <input  [checked]="rate == 1.5 ? true : false"  type="checkbox" id="star1half" name="rating" value="1 and a half" />
    <label class="half" for="star1half" title="Meh - 1.5 stars"></label>
   <!-- 1 -->
    <input  [checked]="rate == 1 ? true : false"  type="checkbox" id="star1" name="rating" value="1" />
    <label class="full" for="star1" title="Sucks big time - 1 star"></label>
   <!-- .5 -->
    <input  [checked]="rate == .5 ? true : false"  type="checkbox" id="starhalf" name="rating" value="half" />
    <label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
</fieldset>