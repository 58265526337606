export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce'
      },
      APPS: {
        SECTION: 'Pages',
        EMAIL: 'Email',
        ATTENDANCE: 'Attendance',
        ORDERS: 'Orders',
        VACATIONS: 'Vacation',
        ACCOUNT: 'Profile',
        SALARY: 'Salary',
        TRANINGS: 'Tranings',
        REQUESTS: 'Requests',
        TODO: 'Todo',
        CALENDAR: 'Timesheet',
        HOME: 'Dashboard',

      },
      ADMIN: {
        COLLAPSIBLE: 'İşçilər',
        LIST: 'Siyahı',
        VIEW: 'Siyahı',
        USER: {
          LIST: 'Siyahı',
          VIEW: 'Siyahı',
          EDIT: 'Düzəliş',
        },
      },
      PAGES: {
        SECTION: 'Pages',
        AUTH: {
          COLLAPSIBLE: 'Authentication',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Forgot Password V1',
          FORGOTPASSWORD2: 'Forgot Password V2',
          RESETPASSWORD1: 'Reset Password V1',
          RESETPASSWORD2: 'Reset Password V2'
        },
        ACCOUNTSETTINGS: 'Account Settings',
        PROFILE: 'Profile',
        FAQ: 'FAQ',
        KB: 'Knowledge Base',
        PRICING: 'Pricing',
        BLOG: {
          COLLAPSIBLE: 'Blog',
          LIST: 'List',
          DETAILS: 'Details',
          EDIT: 'Edit'
        },
        MAIL: {
          COLLAPSIBLE: 'Mail Template',
          WELCOME: 'Welcome',
          RESET: 'Reset Password',
          VERIFY: 'Verify',
          DEACTIVATE: 'Deactivate',
          INVOICE: 'Invoice',
          PROMOTIONAL: 'Promotional'
        },
        MISC: {
          COLLAPSIBLE: 'Miscellaneous',
          COMINGSOON: 'Coming Soon',
          NOTAUTH: 'Not Authorized',
          MAINTENANCE: 'Maintenance',
          ERROR: 'Error'
        }
      },
      UI: {
        SECTION: 'User Interface',
        TYPOGRAPHY: 'Typography',
        COLORS: 'Colors',
        FEATHER: 'Feather',
        CARDS: {
          COLLAPSIBLE: 'Cards',
          BADGE: 'New',
          BASIC: 'Basic',
          ADVANCE: 'Advance',
          STATISTICS: 'Statistics',
          ANALYTICS: 'Analytics',
          ACTIONS: 'Actions'
        },
        COMPONENTS: {
          COLLAPSIBLE: 'Components',
          ALERTS: 'Alerts',
          AVATAR: 'Avatar',
          BADGES: 'Badges',
          BREADCRUMBS: 'Breadcrumbs',
          BUTTONS: 'Buttons',
          CAROUSEL: 'Carousel',
          COLLAPSE: 'Collapse',
          DIVIDER: 'Divider',
          DROPDOWNS: 'Dropdowns',
          GROUP: 'List Group',
          OBJECTS: 'Media Objects',
          MODALS: 'Modals',
          COMPONENT: 'Navs',
          PAGINATION: 'Pagination',
          PBADGES: 'Pill Badges',
          PILLS: 'Pills',
          POPOVERS: 'Popovers',
          PROGRESS: 'Progress',
          RATINGS: 'Ratings',
          SPINNER: 'Spinner',
          TABS: 'Tabs',
          TIMELINE: 'Timeline',
          TOASTS: 'Toasts',
          TOOLTIPS: 'Tooltips'
        },
        EX: {
          COLLAPSIBLE: 'Extension',
          SWEET_ALERTS: 'Sweet Alerts',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'Slider',
          DRAGDROP: 'Drag & Drop',
          TOUR: 'Tour',
          CLIPBOARD: 'Clipboard',
          MEDIAPLAYER: 'Media Player',
          CONTEXTMENU: 'Context Menu',
          SWIPER: 'Swiper',
          TREEVIEW: 'Tree View',
          I18N: 'I18n'
        },
        LAYOUTS: {
          COLLAPSIBLE: 'Page Layouts',
          COLLAPSED_MENU: 'Collapsed Menu',
          BOXED_LAYOUT: 'Boxed Layout',
          WITHOUT_MENU: 'Without Menu',
          LAYOUT_EMPTY: 'Layout Empty',
          LAYOUT_BLANK: 'Layout Blank'
        }
      },
      FT: {
        SECTION: 'Forms & Tables',
        ELEMENT: {
          COLLAPSIBLE: 'Form Elements',
          INPUT: 'Input',
          INPUTGROUPS: 'Input Groups',
          INPUTMASK: 'Input Mask',
          TEXTAREA: 'Textarea',
          CHECKBOX: 'Checkbox',
          RADIO: 'Radio',
          SWITCH: 'Switch',
          SELECT: 'Select',
          NUMBERINPUT: 'Number Input',
          FILEUPLOADER: 'File Uploader',
          QUILLEDITOR: 'Quill Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'Date & Time Picker'
        },
        LAYOUTS: 'Form Layouts',
        WIZARD: 'Form Wizard',
        VALIDATION: 'Form Validations',
        REPEATER: 'Form Repeater',
        TABLE: 'Table',
        DATATABLES: 'DataTables'
      },
      CM: {
        SECTION: 'Charts & Maps',
        CHARTS: {
          COLLAPSIBLE: 'Charts',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS'
        },
        MAPS: 'Google Maps'
      },
      OTHERS: {
        SECTION: 'Others',
        LEVELS: {
          COLLAPSIBLE: 'Menu Levels',
          SECOND: 'Second Level',
          SECOND1: {
            COLLAPSIBLE: 'Second Level',
            THIRD: 'Third Level',
            THIRD1: 'Third Level'
          }
        },
        DISABLED: 'Disabled Menu',
        DOCUMENTATION: 'Documentation',
        SUPPORT: 'Raise Support'
      },
      USER: {
        ACCOUNT: 'Hesabım'
      }
    },
    earning: 'Earning',
    deduction: 'Deduction',
    deduction2: 'Deduction',
    salary: 'Salary',
    vacation: 'Vacation',
    spf: 'Spf',
    unemployment: 'Unemployment',
    "medical.insurance": 'medical insurance ',
    total: 'Total',
    month: 'month',
    tobepaid: 'to be paid',
    employee: 'Employee',
    company: 'Company',
    date: 'Date',
    trainings: 'Trainings',
    common: 'Common',
    main: 'Main',
    condition: 'Condition',
    earned: 'Earned',
    used: 'Used',
    balanced: 'Balanced',
    experience: 'Experience',
    child: 'child',
    name: 'name',
    firstname: 'Firstname',
    fullname: 'Fullname',
    surname: 'Surname',
    'salary.table': 'salary table',
    pin: 'Pin',

    email: 'Email',
    position: 'Position',
    department: 'Department',
    status: 'status',
    address: 'Address',
    photo: 'Photo',
    seria: 'Seria',
    issiedBy: 'NAME OF AUTHORITY ISSUING ID. CARD',
    family: 'Family members',
    relationship: 'Relationship',
    snf: 'S.N.F',
    dob: 'Birthday',
    age: 'Age',
    worked: 'Worked',
    dayOff: 'Dayoff',
    type: 'Type',
    detail: 'Detail',
    project: 'Project',
    description: 'Description',
    vacationDays: 'Vacation Days',
    vacationDate: 'Vacation Dates',
    orderDate: 'Order date',
    balancedVacationDays: 'Balanced vacation date',
    close: 'Close',
    day: 'day',
    edit: 'Edit',
    add: 'Add',
    dateTo: 'Date to',
    dateFrom: 'Date from',
    newRequest: 'New request',
    request: 'Request',
    amount: 'Amount',
    answer: 'Answer',
    answerDate: 'Answer date',
    types: 'Types',
    statuses: 'Statuses',
    selectType: 'Select Type',
    logout: 'Logout',
    save: 'Save',
    cancel: 'Cancel',
    changePass: 'Change password',
    cpassword: 'Confirm password',
    password: 'Password',
    orderTypes: 'Order types',
    newPassword: 'New password',
    approved: 'Approved',
    pending: 'Pending',
    rejected: 'Rejected',
    send: 'Send',
    back: 'back',
    approve: 'Approve',
    setPass: 'Set password',
    enteringSystem: 'Setting the password for entering the system',
    passrequerid: 'Password requerid',
    regular: 'Regular',
    weekend: 'Weekend',
    overtime: 'Overtime',
    "business trip": 'Business trip',
    "public holiday": 'Public holiday',
    "vacation/annual": "Vacation/annual",
    "academic": "Academik",
    sick: "Sick leave",
    unpaid: "Unpaid leave",
    maternity: 'Maternity leave',
    social: 'SosiaSocial leavel',
    compensation: "Compensation leave",
    dayoff: 'Day off',
    'sick.leave': 'medical',
    monthly: "Monthly",
    yearly: "Yearly",
    year: "Year",
    jobs:"Jobs",
    more:"More",
    seemore:"See More",
    "evening.payment":"Evening payment"

  }
};
