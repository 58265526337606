import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';


@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    public User = new BehaviorSubject<any>(this.StorageService.getUser());
 
    constructor(private StorageService: StorageService) { }


}
