
import { Role } from './role';

export class User {
  id: number;
  email: string;
  password: string;
  dob: Date;
  fullname: string;
  firstname: string;
  username: string;
  lastname: string;
  fin: string;
  height: number;
  weight: number;
  balance: number;
  age: number;
  win: number;
  lost: number;
  draw: number;
  knockoutCount: number;
  photo: string;
  role: Role;
  credit: number;
  roleId: number;
  rate:number;
  phone:string;
  knockout:number;
  knockoutWin:number;
  knockoutLost:number;
  draws:number;
  normal:number;
  last5Fight:number[];
  isActive: boolean;
}
