import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarComponent } from './star.component';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';



@NgModule({
  declarations: [StarComponent],
  imports: [RouterModule, CoreCommonModule],
  exports: [StarComponent]
})
export class StarModule { }
