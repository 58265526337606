import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service'
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class GeneralService extends BaseService {

    public getDatabases(request: any): Observable<any> {
        return this.get<any>('/api/database/', request)
    }
    public getCompanies(request: any): Observable<any> {
        return this.get<any>('/api/company/', request)
    }
   
   

}
