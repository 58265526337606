import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memberType'
})
export class MemberTypePipe implements PipeTransform {

  transform(value: unknown,): unknown {


    if(value >=18){
      return 'adult'
    }
    else if( value<18 && value>=14){
      return 'teenager'
    }
    
    return 'young';
  }

}
