import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';

@Directive({
  selector: '[role]'
})
export class RoleDirective {

  constructor(private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private userService: AuthenticationService) {
  }

  UserAccess = this.userService.isAdmin;
  @Input() set role(allowedRole: string) {
    if (allowedRole == "Admin") {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }


}
