import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe/safe.pipe';
import { AgePipe } from './age/age.pipe';
import { MemberTypePipe } from './member-type/member-type.pipe';



@NgModule({
  declarations: [
    SafePipe,
    AgePipe,
    MemberTypePipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    SafePipe,
    MemberTypePipe,
    AgePipe
  ]
})
export class PipesModule { }
